import React from 'react';
import {
  FlatList,
} from 'react-native';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as WebBrowser from 'expo-web-browser';

import ListItemSeparator from '../components/ListItemSeparator';
import ListItem from '../components/ListItem';
import { fetchMorePayslips, refreshFetchPayslips, fetchPayslipIfNeeded, fetchPayslipsIfNeeded } from '../actions/payslips';
import withLoader from '../components/withLoader';
import withFetchError from '../components/withFetchError';
import { monthString } from '../helpers/time';
import withNoItems from '../components/withNoItems';
import NotificationResourceTypes from '../constants/NotificationResourceTypes';
import withFetch from '../components/withFetch';
import withNotificationClearing from '../components/withNotificationClearing';
import { useIsFocused } from '@react-navigation/native';

const notificationType = NotificationResourceTypes.Payslip;

const PayslipsContainer = ({ payslips, notifications, fetchPayslipIfNeeded, fetchMorePayslips, refreshFetchPayslips }) => {
  const isFocused = useIsFocused();
  const _onPress = id => async () => {
    fetchPayslipIfNeeded(id).then(res => {
      WebBrowser.openBrowserAsync(res.temp_url);
    });
  };

  const _onEndReached = () => {
    if (isFocused) {
      fetchMorePayslips();
    }
  };

  const _onRefresh = () => {
    refreshFetchPayslips();
  };

  const _renderItem = ({ item, index, section }) => {
    return (
      <ListItem
        title={ item.title }
        subtitle={ item.subtitle }
        attention={ item.attention }
        onPress={ _onPress(item.id) }
      />
    );
  };

  if (!payslips.items.length) {
    return null;
  }

  const listData = payslips.items.map(item => ({
    id: item.id,
    title: `${ monthString(item.month) } ${ item.year }`,
    subtitle: 'Lönespecifikation',
    attention: !!notifications.items.find(i => i.data.resource_type === notificationType && i.data.resource_id === item.id),
  }));

  return (
    <FlatList
      contentContainerStyle={ { paddingBottom: 24 } }
      keyExtractor={ item => `${ item.id }` }
      data={ listData }
      renderItem={ _renderItem }
      ItemSeparatorComponent={ ListItemSeparator }
      onEndReached={ _onEndReached }
      onEndReachedThreshold={ 0.75 }
      onRefresh={ _onRefresh }
      refreshing={ payslips.refreshing }
    />
  );
  // }
};

const mapState = state => {
  const { payslips, notifications } = state;

  return {
    loading: payslips.items.length === 0 && payslips.fetching && !payslips.refreshing,
    noItems: payslips.items.length === 0 && !payslips.fetching,
    fetchError: payslips.fetchError,
    payslips,
    notifications,
  };
};

const mapDispatch = dispatch => {
  return {
    fetch: () => dispatch(fetchPayslipsIfNeeded()),
    refreshFetchPayslips: () => dispatch(refreshFetchPayslips()),
    fetchMorePayslips: () => dispatch(fetchMorePayslips()),
    fetchPayslipIfNeeded: id => dispatch(fetchPayslipIfNeeded(id)),
  };
};

export default compose(
  connect(mapState, mapDispatch),
  withNotificationClearing(notificationType),
  withNoItems({
    text: 'Du har inga lönespecifikationer ännu',
  }),
  withLoader,
  withFetchError,
  withFetch
)(PayslipsContainer);
