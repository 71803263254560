import React, { useState } from 'react';
import { View, StyleSheet, TextInput, ScrollView, TouchableOpacity } from 'react-native';
import * as Icon from '@expo/vector-icons';
import Colors from '../constants/Colors';
import InputButton from './InputButton';
import Text from './Text';
import Fonts from '../constants/Fonts';
import TextButton from './TextButton';
import Modal from './Modal';
import LineItem from './LineItem';
import { CostType } from '../constants/types';

type Props = {
  cost: string;
  description: string;
  onClose: () => void;
  costType: CostType;
  onChangeCost: (input: string) => void;
  onChangeDescription: (input: string) => void;
  onSave: (addNew: boolean) => void;
  onChangeType: (item: CostType) => void;
  costTypes: CostType[];
}

const ExpenseInput = ({ cost, description, onClose, costType, onChangeCost, onChangeDescription, onSave, onChangeType, costTypes }: Props) => {
  const [costTypeModalOpen, toggleCostTypeModal] = useState(false);
  const [costTypeCategoryModalOpen, toggleCostTypeCategoryModal] = useState(false);
  const [selectedCostCategory, setCostCategory] = useState({
    id: 1,
    title: 'Utlägg och KM-ersättning',
  });

  const costTypeCategories = [
    {
      id: 1,
      title: 'Utlägg och KM-ersättning',
    },
    {
      id: 2,
      title: 'Material och verktyg',
    },
  ];

  const onChangeCostCategory = (item: any) => {
    setCostCategory(item);

    if ((item.id === 1 && costType.type !== 'travel') || (item.id === 2 && costType.type !== 'material')) {
      onChangeType(costTypes.find((type) =>
        type.type === (item.id === 1 ? 'travel' : 'material') &&
        (type.type === 'material' ? type.id === 8 : type.id === 4)
      ));
    }
    toggleCostTypeCategoryModal(false);
  };

  const onChange = (item: CostType) => {
    toggleCostTypeModal(false);
    onChangeType(item);
  };

  return (
    <View style={ styles.container }>
      <InputButton
        labelStyle={ { marginLeft: 4 } }
        iconName={ 'sort' }
        label={ 'Utläggsskategori' }
        onPress={ () => toggleCostTypeCategoryModal(true) }
        value={ selectedCostCategory.title }
      />
      {
        selectedCostCategory &&
        <View style={ { marginTop: 16 } }>
          <InputButton
            labelStyle={ { marginLeft: 4 } }
            iconName={ 'sort' }
            label={ 'Typ' }
            onPress={ () => toggleCostTypeModal(true) }
            value={ costType.title }
          />
          { !!costType && <Text style={ styles.information }>
            { costType.message }
          </Text> }
          { !!costType.unit &&
            <>
              <Text style={ styles.label }>
                Kostnad
              </Text>
              <View style={ styles.costInputContainer }>
                <View style={ styles.costInput }>
                  <TextInput
                    placeholder={ '0' }
                    placeholderTextColor={ Colors.borderDark }
                    value={ cost }
                    keyboardType={ 'numeric' }
                    onChangeText={ onChangeCost }/>
                </View>
                <Text>
                  { costType.unit }
                </Text>
              </View>
              { !!(costType.id === 4) && <Text style={ styles.costMeta }>Värdet kommer avrundas upp till närmsta heltal</Text> }
            </>
          }
          {
            costType.id !== 4 &&
            <>
              <Text style={ styles.label }>
                Beskriv utlägget
              </Text>
              <View style={ styles.descriptionInput }>
                <TextInput
                  style={ { flex: 1 } }
                  multiline={ true }
                  placeholder={ 'Skriv här...' }
                  placeholderTextColor={ Colors.borderDark }
                  value={ description }
                  onChangeText={ onChangeDescription }
                />
              </View>
            </>
          }
        </View>
      }
      <View style={ styles.addContainer }>
        <TextButton
          iconName={ 'plus-circle' }
          label={ 'Lägg till nytt utlägg' }
          labelColor={ Colors.primaryBackground }
          onPress={ () => onSave(true) }/>
      </View>
      <View style={ styles.closeButton }>
        <TouchableOpacity onPress={ onClose }>
          <Icon.AntDesign
            size={ 22 }
            color={ Colors.primaryBackground }
            name={ 'closecircle' }
          />
        </TouchableOpacity>
      </View>
      <Modal
        onClose={ () => toggleCostTypeCategoryModal(false) }
        visible={ costTypeCategoryModalOpen }
        title={ 'Utläggskategori' }
      >
        <ScrollView>
          { costTypeCategories.map((option) =>
            <LineItem.Simple
              key={ option.id }
              label={ option.title }
              value={ option }
              checked={ costType.id === option.id }
              onChange={ onChangeCostCategory }
            />
          )}
        </ScrollView>
      </Modal>
      <Modal
        onClose={ () => toggleCostTypeModal(false) }
        visible={ costTypeModalOpen }
        title={ 'Utläggstyp' }
      >
        <ScrollView>
          { costTypes.filter(option => selectedCostCategory.id === 1 ? option.type === 'travel' : option.type === 'material').map((option: CostType) =>
            <LineItem.Simple
              key={ option.id }
              label={ option.title }
              value={ option }
              checked={ costType.id === option.id }
              onChange={ onChange }
            />
          )}
        </ScrollView>
      </Modal>
    </View>
  );
};

export default ExpenseInput;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 12,
    paddingTop: 32,
    borderWidth: 1,
    borderColor: Colors.border,
    marginHorizontal: -12,
    marginBottom: 24,
  },
  label: {
    marginVertical: 8,
    fontFamily: Fonts.medium,
    marginLeft: 4,
  },
  information: {
    marginTop: 8,
    fontSize: 14,
    color: Colors.secondaryText,
  },
  costMeta: {
    color: Colors.secondaryText,
    fontSize: 14,
  },
  costInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  costInput: {
    width: 124,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.border,
    marginRight: 12,
    marginBottom: 8,
    paddingVertical: 24,
    paddingHorizontal: 16,
  },
  descriptionInput: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.border,
    padding: 16,
    height: 100,
  },
  addContainer: {
    marginTop: 24,
    marginHorizontal: -16,
    padding: 16,
    borderTopWidth: 1,
    borderColor: Colors.border,
  },
  closeButton: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
});
