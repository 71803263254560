import React from 'react';
import { Platform, StatusBar, StyleSheet, View, Text, ScrollView, AppState } from 'react-native';
import { connect } from 'react-redux';
import moment from 'moment';

import AppNavigator from '../navigation/AppNavigator';
import { handlePushNotification } from '../actions/push-notifications';
import { connectionChange } from '../actions/connection';
import { destroySession } from '../actions/auth';
import { returnFromBankidApp } from '../actions/bankid-app';
import { finishBankidAuth } from '../actions/bankid';
import { updateUserPolicyConsent } from '../actions/user';
import NetInfo from '@react-native-community/netinfo';
import { NavigationContainer } from '@react-navigation/native';
import Modal from '../components/Modal';
import { A } from '@expo/html-elements';

import Colors from '../constants/Colors';
import { PrimaryButton } from '../components/Button';
import ListItemCheckbox from '../components/ListItemCheckbox';

class AppContainer extends React.Component {

  state = {
    hasReadWorkplaceTerms: false,
    appState: AppState.currentState,
  }

  networkEvent = null;
  appStateEvent = null;
  componentDidMount() {
    // this._notificationSubscription = Notifications.addListener(this._handleNotification);
    this.networkEvent = NetInfo.addEventListener(this._handleConnectionChange);
    this.appStateEvent = AppState.addEventListener('change', this._handleStateChangeActive);
  }

  componentWillUnmount() {
    // this._notificationSubscription.remove();
    this.appStateEvent.remove();
    this.networkEvent();
  }

  _handleNotification = (notification) => {
    const { handlePushNotification } = this.props;

    handlePushNotification(notification);
  };

  _handleConnectionChange = async (state) => {
    const { connectionChange } = this.props;
    const isConnected = state.isConnected;
    // await NetInfo.isConnected.fetch();

    connectionChange(isConnected);
  };

  _handleStateChangeActive = (state) => {
    if (state === 'active') {
      const {
        accessTokenExpiresAt,
        destroySession,
        bankidAppPending,
        returnFromBankidApp,
        finishBankidAuth,
      } = this.props;

      // possibly returning from bankid app (android)
      if (bankidAppPending) {
        returnFromBankidApp();
        finishBankidAuth();
      }

      // possibly log user out
      if (!accessTokenExpiresAt) {
        return;
      }

      const expiresAt = moment.utc(accessTokenExpiresAt).utc();
      const now = moment().utc();

      if (now.isAfter(expiresAt)) {
        destroySession();
      }
    }
  };

  _onAcceptTerms = () => {
    const { updateUserPolicyConsent } = this.props;
    updateUserPolicyConsent();
  }

  _onHasReadWorkplaceTerms = (e) => {
    this.setState({ hasReadWorkplaceTerms: e.checked });
  }

  render() {
    const { accessToken, userData } = this.props;
    const { hasReadWorkplaceTerms } = this.state;

    return (
      <View style={ styles.container }>
        {Platform.OS === 'ios' && <StatusBar barStyle={ 'light-content' } />}
        <NavigationContainer>
          <AppNavigator />
        </NavigationContainer>
        <Modal
          disableClose={ true }
          visible={ accessToken && userData.has_read_policy_information !== undefined && !userData.has_read_policy_information }
          title={ 'Arbetsmiljöinformation' }
        >
          <ScrollView>
            <View style={ styles.modalContainer }>
              <Text>
                För att kunna använda den här appen måste du bekräfta att du tagit del av våra arbetsmiljöinformation, läs igenom den i länken nedan:
              </Text>
              <A style={ styles.linkStyle } href={ 'https://veteranpoolen.se/information-page/allman-arbetsmiljoinformation/' }>
                Arbetsmiljöinformation
              </A>
              <View style={ { marginLeft: -24 } }>
                <ListItemCheckbox
                  label={ 'Jag har tagit del av arbetsmiljöinformationen' }
                  checked={ hasReadWorkplaceTerms }
                  onChange={ (v) => this._onHasReadWorkplaceTerms(v) }
                />
              </View>
              <PrimaryButton
                onPress={ this._onAcceptTerms }
                title={ 'Bekräfta' }
                disabled={ !hasReadWorkplaceTerms }
              />
            </View>
          </ScrollView>
        </Modal>
      </View>
    );
  }

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  modalContainer: {
    paddingBottom: 24,
    paddingHorizontal: 24,
  },
  linkStyle: {
    marginTop: 16,
    color: Colors.secondaryText,
    textDecorationLine: 'underline',
  },
});

const mapState = (state) => {
  const { auth, bankidApp, user } = state;

  return {
    accessToken: auth.accessToken,
    userData: user.data,
    accessTokenExpiresAt: auth.accessTokenExpiresAt,
    bankidAppPending: bankidApp.launched && !bankidApp.returned,
  };
};

const mapDispatch = {
  handlePushNotification,
  connectionChange,
  destroySession,
  returnFromBankidApp,
  finishBankidAuth,
  updateUserPolicyConsent,
};

export default connect(mapState, mapDispatch)(AppContainer);
