import {
  FETCH_BANKID_COLLECT_REQUEST,
  FETCH_BANKID_COLLECT_SUCCESS,
  FETCH_BANKID_COLLECT_FAILURE,
  FETCH_BANKID_COLLECT_RETRY,
} from '../constants/ActionTypes';

export default (state = {
  fetching: false,
  fetchError: false,
  orderRef: null,
  status: null,
  hintCode: null,
  message: null,
  accessToken: null,
  accessTokenExpiresAt: null,
  fetchedAt: null
}, action) => {
  switch (action.type) {
    case FETCH_BANKID_COLLECT_REQUEST:
      return Object.assign({}, state, {
        fetching: true,
        fetchError: false,
        message: action.payload.message,
      });
    case FETCH_BANKID_COLLECT_SUCCESS:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: false,
        orderRef: action.payload.orderRef,
        status: action.payload.status,
        hintCode: action.payload.hintCode,
        message: action.payload.message,
        accessToken: action.payload.accessToken,
        accessTokenExpiresAt: action.payload.accessTokenExpiresAt,
        fetchedAt: action.payload.fetchedAt,
        qrData: null,
      });
    case FETCH_BANKID_COLLECT_RETRY:
      return Object.assign({}, state, {
        orderRef: action.payload.orderRef,
        qrData: action.payload.qrData,
      });
    case FETCH_BANKID_COLLECT_FAILURE:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: true,
        qrData: null,
      });
    default:
      return state;
  }
}
