// root
export const ROOT_RESET = 'ROOT_RESET';

// auth
export const AUTH_CREATE = 'AUTH_CREATE';
export const AUTH_DESTROY = 'AUTH_DESTROY';

// bankid-app
export const BANKID_APP_LAUNCHED = 'BANKID_APP_LAUNCHED';
export const BANKID_APP_RETURNED = 'BANKID_APP_RETURNED';
export const BANKID_APP_FINISHED = 'BANKID_APP_FINISHED';

// bankid-collect
export const FETCH_BANKID_COLLECT_REQUEST = 'FETCH_BANKID_COLLECT_REQUEST';
export const FETCH_BANKID_COLLECT_SUCCESS = 'FETCH_BANKID_COLLECT_SUCCESS';
export const FETCH_BANKID_COLLECT_RETRY = 'FETCH_BANKID_COLLECT_RETRY';
export const FETCH_BANKID_COLLECT_FAILURE = 'FETCH_BANKID_COLLECT_FAILURE';

// bankid-token
export const FETCH_BANKID_TOKEN_REQUEST = 'FETCH_BANKID_TOKEN_REQUEST';
export const FETCH_BANKID_TOKEN_SUCCESS = 'FETCH_BANKID_TOKEN_SUCCESS';
export const FETCH_BANKID_TOKEN_FAILURE = 'FETCH_BANKID_TOKEN_FAILURE';

// Login
export const FETCH_MANUAL_LOGIN_REQUEST = 'FETCH_MANUAL_LOGIN_REQUEST';
export const FETCH_MANUAL_LOGIN_SUCCESS = 'FETCH_MANUAL_LOGIN_SUCCESS';
export const FETCH_MANUAL_LOGIN_FAILURE = 'FETCH_MANUAL_LOGIN_FAILURE';
export const RESET_MANUAL_LOGIN_FAILURE = 'RESET_MANUAL_LOGIN_FAILURE';

// connection
export const CONNECTION_CHANGE = 'CONNECTION_CHANGE';

// messages
export const FETCH_MESSAGES_REQUEST = 'FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_FAILURE = 'FETCH_MESSAGES_FAILURE';
export const REFRESH_MESSAGES = 'REFRESH_MESSAGES';
export const MESSAGES_CURRENT_PAGE = 'MESSAGES_CURRENT_PAGE';
export const UPDATE_MESSAGE_ATTENDING_REQUEST = 'UPDATE_MESSAGE_ATTENDING_REQUEST';
export const UPDATE_MESSAGE_ATTENDING_SUCCESS = 'UPDATE_MESSAGE_ATTENDING_SUCCESS';
export const UPDATE_MESSAGE_ATTENDING_FAILURE = 'UPDATE_MESSAGE_ATTENDING_FAILURE';

// notifications
export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const UPDATE_NOTIFICATION_REQUEST = 'UPDATE_NOTIFICATION_REQUEST';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_FAILURE = 'UPDATE_NOTIFICATION_FAILURE';
export const REMOVE_NOTIFICATIONS = 'REMOVE_NOTIFICATIONS';
export const UPDATE_NOTIFICATIONS_REQUEST = 'UPDATE_NOTIFICATIONS_REQUEST';
export const UPDATE_NOTIFICATIONS_SUCCESS = 'UPDATE_NOTIFICATIONS_SUCCESS';
export const UPDATE_NOTIFICATIONS_FAILURE = 'UPDATE_NOTIFICATIONS_FAILURE';

// offices
export const FETCH_OFFICES_REQUEST = 'FETCH_OFFICES_REQUEST';
export const FETCH_OFFICES_SUCCESS = 'FETCH_OFFICES_SUCCESS';
export const FETCH_OFFICES_FAILURE = 'FETCH_OFFICES_FAILURE';

// push-badge
export const GET_PUSH_BADGE_NUMBER = 'GET_PUSH_BADGE_NUMBER';
export const EDIT_PUSH_BADGE_NUMBER = 'EDIT_PUSH_BADGE_NUMBER';

// push-notifications
export const PUSH_NOTIFICATION_RECEIVED = 'PUSH_NOTIFICATION_RECEIVED';
export const PUSH_NOTIFICATION_SELECTED = 'PUSH_NOTIFICATION_SELECTED';
export const PUSH_NOTIFICATION_HANDLED = 'PUSH_NOTIFICATION_HANDLED';

// push-settings
export const FETCH_PUSH_SETTINGS_REQUEST = 'FETCH_PUSH_SETTINGS_REQUEST';
export const FETCH_PUSH_SETTINGS_SUCCESS = 'FETCH_PUSH_SETTINGS_SUCCESS';
export const FETCH_PUSH_SETTINGS_FAILURE = 'FETCH_PUSH_SETTINGS_FAILURE';
export const EDIT_PUSH_SETTING = 'EDIT_PUSH_SETTING';
export const UPDATE_PUSH_SETTING_REQUEST = 'UPDATE_PUSH_SETTING_REQUEST';
export const UPDATE_PUSH_SETTING_SUCCESS = 'UPDATE_PUSH_SETTING_SUCCESS';
export const UPDATE_PUSH_SETTING_FAILURE = 'UPDATE_PUSH_SETTING_FAILURE';

// push-token
export const GET_PUSH_TOKEN_REQUEST = 'GET_PUSH_TOKEN_REQUEST';
export const GET_PUSH_TOKEN_SUCCESS = 'GET_PUSH_TOKEN_SUCCESS';
export const GET_PUSH_TOKEN_FAILURE = 'GET_PUSH_TOKEN_FAILURE';
export const UPDATE_PUSH_TOKEN_REQUEST = 'UPDATE_PUSH_TOKEN_REQUEST';
export const UPDATE_PUSH_TOKEN_SUCCESS = 'UPDATE_PUSH_TOKEN_SUCCESS';
export const UPDATE_PUSH_TOKEN_FAILURE = 'UPDATE_PUSH_TOKEN_FAILURE';
export const CLEAR_PUSH_TOKEN = 'CLEAR_PUSH_TOKEN';

// payslips
export const FETCH_PAYSLIPS_REQUEST = 'FETCH_PAYSLIPS_REQUEST';
export const FETCH_PAYSLIPS_SUCCESS = 'FETCH_PAYSLIPS_SUCCESS';
export const FETCH_PAYSLIPS_FAILURE = 'FETCH_PAYSLIPS_FAILURE';
export const FETCH_PAYSLIP_REQUEST = 'FETCH_PAYSLIP_REQUEST';
export const FETCH_PAYSLIP_SUCCESS = 'FETCH_PAYSLIP_SUCCESS';
export const FETCH_PAYSLIP_FAILURE = 'FETCH_PAYSLIP_FAILURE';
export const REFRESH_PAYSLIPS = 'REFRESH_PAYSLIPS';
export const PAYSLIPS_CURRENT_PAGE = 'PAYSLIPS_CURRENT_PAGE';

// services
export const FETCH_SERVICES_REQUEST = 'FETCH_SERVICES_REQUEST';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_FAILURE = 'FETCH_SERVICES_FAILURE';
export const ADD_SERVICE = 'ADD_SERVICE';
export const REMOVE_SERVICE = 'REMOVE_SERVICE';
export const UPDATE_SERVICE_REQUEST = 'UPDATE_SERVICE_REQUEST';
export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS';
export const UPDATE_SERVICE_FAILURE = 'UPDATE_SERVICE_FAILURE';

// tags
export const FETCH_TAGS_REQUEST = 'FETCH_TAGS_REQUEST';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_FAILURE = 'FETCH_TAGS_FAILURE';
export const ADD_TAG = 'ADD_TAG';
export const REMOVE_TAG = 'REMOVE_TAG';
export const UPDATE_TAG_REQUEST = 'UPDATE_TAG_REQUEST';
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';
export const UPDATE_TAG_FAILURE = 'UPDATE_TAG_FAILURE';

// jobs
export const FETCH_JOBS_REQUEST = 'FETCH_JOBS_REQUEST';
export const FETCH_JOBS_SUCCESS = 'FETCH_JOBS_SUCCESS';
export const FETCH_JOBS_FAILURE = 'FETCH_JOBS_FAILURE';
export const FETCH_MY_JOBS_REQUEST = 'FETCH_MY_JOBS_REQUEST';
export const FETCH_MY_JOBS_SUCCESS = 'FETCH_MY_JOBS_SUCCESS';
export const FETCH_MY_JOBS_FAILURE = 'FETCH_MY_JOBS_FAILURE';
export const UPDATE_JOBS_SUCCESS = 'UPDATE_JOBS_SUCCESS';
export const FETCH_MY_CLOSED_JOBS_REQUEST = 'FETCH_MY_CLOSED_JOBS_REQUEST';
export const FETCH_MY_CLOSED_JOBS_SUCCESS = 'FETCH_MY_CLOSED_JOBS_SUCCESS';
export const FETCH_MY_CLOSED_JOBS_FAILURE = 'FETCH_MY_CLOSED_JOBS_FAILURE';
export const FETCHING_MORE_JOBS = 'FETCHING_MORE_JOBS';
export const REFRESH_JOBS = 'REFRESH_JOBS';
export const JOBS_CURRENT_PAGE = 'JOBS_CURRENT_PAGE';
export const MY_JOBS_CURRENT_PAGE = 'MY_JOBS_CURRENT_PAGE';
export const MY_CLOSED_JOBS_CURRENT_PAGE = 'MY_CLOSED_JOBS_CURRENT_PAGE';
export const CLEAR_JOBS = 'CLEAR_JOBS';
export const EDIT_JOBS_SHOW_EXTERNAL = 'EDIT_JOBS_SHOW_EXTERNAL';
export const EDIT_JOBS_SEARCH_QUERY = 'EDIT_JOBS_SEARCH_QUERY';
export const EDIT_SELECTED_JOB = 'EDIT_SELECTED_JOB';

// job-applications
export const UPDATE_APPLY_JOB_REQUEST = 'UPDATE_APPLY_JOB_REQUEST';
export const UPDATE_APPLY_JOB_SUCCESS = 'UPDATE_APPLY_JOB_SUCCESS';
export const UPDATE_APPLY_JOB_FAILURE = 'UPDATE_APPLY_JOB_FAILURE';
export const UPDATE_UNAPPLY_JOB_REQUEST = 'UPDATE_UNAPPLY_JOB_REQUEST';
export const UPDATE_UNAPPLY_JOB_SUCCESS = 'UPDATE_UNAPPLY_JOB_SUCCESS';
export const UPDATE_UNAPPLY_JOB_FAILURE = 'UPDATE_UNAPPLY_JOB_FAILURE';

// user
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const EDIT_USER = 'EDIT_USER';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';

export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';

export const SAVE_FILTERS = 'SAVE_FILTERS';


// time reports
export const FETCH_COST_TYPES_REQUEST = 'FETCH_COST_TYPES_REQUEST';
export const FETCH_COST_TYPES_SUCCESS = 'FETCH_COST_TYPES_SUCCESS';
export const FETCH_COST_TYPES_FAILURE = 'FETCH_COST_TYPES_FAILURE';
export const FETCH_TIME_REPORTS_REQUEST = 'FETCH_TIME_REPORTS_REQUEST';
export const FETCH_TIME_REPORTS_SUCCESS = 'FETCH_TIME_REPORTS_SUCCESS';
export const FETCH_TIME_REPORTS_FAILURE = 'FETCH_TIME_REPORTS_FAILURE';
