import React from 'react';
import { connect } from 'react-redux';

import MainTabNavigator from '../navigation/MainTabNavigator';
import { fetchUserIfNeeded } from '../actions/user';
import { fetchPayslipsIfNeeded } from '../actions/payslips';
import { fetchJobsIfNeeded } from '../actions/jobs';
import { fetchNotificationsIfNeeded, setBadgeNumberToNotificationsCount, markNotificationRead } from '../actions/notifications';
import { fetchMessagesIfNeeded } from '../actions/messages';
import { getUpdatePushTokenIfNeeded } from '../actions/push-token';
import { fetchServicesIfNeeded } from '../actions/services';
import { fetchTagsIfNeeded } from '../actions/tags';
import { fetchOfficesIfNeeded } from '../actions/offices';
import Loader from '../components/Loader';
import { Platform } from 'react-native';

class MainTabContainer extends React.Component {
  static router = MainTabNavigator.router;

  componentDidMount() {
    const { fetch } = this.props;

    fetch();

    this.clearNotifications()
  }

  clearNotifications = () => {
    const { myJobs, notifications, markNotificationRead } = this.props;

    notifications.forEach(notif => {
      if (!myJobs.find(job => job.id === notif.data.resource_id)) {
        markNotificationRead(notif.id)
      }
    })
  }

  render() {
    if (this.props.loading) {
      return <Loader />;
    }

    return (
      <MainTabNavigator navigation={this.props.navigation} />
    );
  }

}

const mapState = state => {
  const { user, notifications, jobs } = state;
  return {
    loading: !user.fetchedAt,
    notifications: notifications.items,
    myJobs: jobs.myJobs,
  };
};

const mapDispatch = dispatch => {
  return {
    fetch: () => dispatch((_, getState) => {
      dispatch(fetchUserIfNeeded()).then(async() => {
        const { user } = getState();

        if (user.fetchedAt) {
          dispatch(fetchPayslipsIfNeeded());
          dispatch(fetchNotificationsIfNeeded())?.then(() => {
            dispatch(setBadgeNumberToNotificationsCount());
          });
          dispatch(fetchMessagesIfNeeded());
          dispatch(fetchServicesIfNeeded());
          dispatch(fetchTagsIfNeeded());
          await dispatch(fetchOfficesIfNeeded());
          dispatch(fetchJobsIfNeeded());
        }

        if (Platform.OS !== 'web') {
          dispatch(getUpdatePushTokenIfNeeded());
        }
      });
    }),
    markNotificationRead: id => dispatch(markNotificationRead(id)),
  };
};

export default connect(mapState, mapDispatch)(MainTabContainer);
