import { Platform } from 'react-native';
import * as Linking from 'expo-linking';
import { BANKID_APP_LAUNCHED, BANKID_APP_RETURNED, BANKID_APP_FINISHED } from '../constants/ActionTypes';
import * as IntentLauncher from 'expo-intent-launcher';
import { reportRequestError } from '../helpers/request-errors';

const bankidAppLaunched = {
  type: BANKID_APP_LAUNCHED,
};

const bankidAppReturned = {
  type: BANKID_APP_RETURNED,
};

const bankidAppFinished = {
  type: BANKID_APP_FINISHED,
};

export const openBankidApp = () => async (dispatch, getState) => {
  const autoStartToken = getState().bankidToken.autoStartToken;

  let url;

  try {
    if (Platform.OS === 'ios') {
      const redirectUrl = Linking.createURL('login/bankid', { bankidSuccess: true });
      url = `https://app.bankid.com/?autostarttoken=${ autoStartToken }&redirect=${ encodeURIComponent(redirectUrl) }`;
      dispatch(bankidAppLaunched);
      await Linking.openURL(url);
    } else {
      url = `bankid:///?autostarttoken=${ autoStartToken }&redirect=null`;

      Linking.canOpenURL(url).then(supported => {
        if (!supported) {
          alert('Vappen kräver Mobilt BankID på enheten för att logga in.');
        }
      }).catch(err => reportRequestError(err, { showAlert: false }));

      dispatch(bankidAppLaunched);
      if (Platform.OS === 'android') {
        await IntentLauncher.startActivityAsync('android.intent.action.VIEW', {
          data: url,
          packageName: 'com.bankid.bus',
        });
      } else if (Platform.OS === 'web') {
        await Linking.openURL(url);
      }
    }
  } catch (e) {
    reportRequestError(e, { showAlert: false });
  }
};

export const returnFromBankidApp = () => dispatch => {
  dispatch(bankidAppReturned);
};

export const finishBankidApp = () => dispatch => {
  dispatch(bankidAppFinished);
};
