import {
  FETCH_BANKID_COLLECT_REQUEST,
  FETCH_BANKID_COLLECT_SUCCESS,
  FETCH_BANKID_COLLECT_FAILURE,
  FETCH_BANKID_COLLECT_RETRY,
} from '../constants/ActionTypes';
import { GET } from '../services/Api';
import { reportRequestError } from '../helpers/request-errors';
import { finishBankidApp } from './bankid-app';
import { logIn } from './auth';

const fetchBankidCollectRequest = ({ message }) => {
  return {
    type: FETCH_BANKID_COLLECT_REQUEST,
    payload: {
      message,
    },
  };
};

const fetchBankidCollectRetry = json => {
  return {
    type: FETCH_BANKID_COLLECT_RETRY,
    payload: {
      orderRef: json.result.orderRef,
      qrData: json.result.qrData,
    },
  };
};

const fetchBankidCollectSuccess = json => {
  return {
    type: FETCH_BANKID_COLLECT_SUCCESS,
    payload: {
      orderRef: json.result.orderRef,
      status: json.result.status,
      hintCode: json.result.hintCode,
      message: json.result.message,
      fetchedAt: Date.now(),
      accessToken: json.result.access_token,
      accessTokenExpiresAt: json.result.access_token_expires_at,
    },
  };
};

const fetchBankidCollectFailure = {
  type: FETCH_BANKID_COLLECT_FAILURE,
};

export const fetchBankidCollect = (orderRef, message) => async (dispatch, getState) => {
  dispatch(fetchBankidCollectRequest({ message: message || 'Var vänlig öppna BankID appen för att signera din inloggning' }));
  try {
    const res = await GET('/auth/collect-qr', { orderRef });

    if (!res.ok) {
      if (res.json.status === 'failed' || res.status !== 408) throw res;
      else {
        dispatch(fetchBankidCollectRetry(res.json));
        setTimeout(() => dispatch(fetchBankidCollect(orderRef, res.json.result.message)), 1000);
      }
    } else {
      dispatch(fetchBankidCollectSuccess(res.json));
      dispatch(finishBankidApp());
      const bankidCollect = getState().bankidCollect;

      if (bankidCollect.accessToken) {
        dispatch(logIn({
          accessToken: bankidCollect.accessToken,
          accessTokenExpiresAt: bankidCollect.accessTokenExpiresAt,
        }));
      }
    }
  } catch (res) {
    dispatch(fetchBankidCollectFailure);
    reportRequestError(res, { showAlert: false });
  }
};
