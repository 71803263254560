import React from 'react';
import {
  StyleSheet,
  View
} from 'react-native';
import CheckBox from 'react-native-modest-checkbox';

import Fonts from '../constants/Fonts';
import Colors from '../constants/Colors';
import CheckedImage from '../assets/images/checkbox-checked.png';
import UncheckedImage from '../assets/images/checkbox-unchecked.png';
import { selection as hapticSelection } from '../helpers/haptic';

export default (props) => {
  const {
    label,
    checked,
    onChange,
    disabled,
    labelStyleFromProps
  } = props;

  const labelStyle = [styles.label];

  if (checked) {
    labelStyle.push(styles.labelChecked);
  }

  if (labelStyleFromProps) {
    labelStyle.push(labelStyleFromProps);
  }

  const hapticSelectionBefore = (callback) => {
    return (...args) => {
      hapticSelection();
      return callback(...args);
    };
  };

  const onChangeHandler = hapticSelectionBefore(onChange);

  return (
    <View style={styles.container}>
      <CheckBox
        label={label}
        labelStyle={labelStyle}
        checkboxStyle={{
          width: 24,
          height: 24,
        }}
        containerStyle={[styles.checkboxContainer, disabled && styles.disabledContainer]}
        checked={checked}
        disabled={disabled}
        onChange={onChangeHandler}
        checkedImage={CheckedImage}
        uncheckedImage={UncheckedImage}
        noFeedback={true}
        numberOfLabelLines={3}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 24,
    paddingVertical: 18,
    backgroundColor: '#fff',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkboxContainer: {
    flex: 1,
  },
  disabledContainer: {
    opacity: 0.5,
  },
  label: {
    color: Colors.secondaryText,
    marginLeft: 2,
  },
  labelChecked: {
    fontFamily: Fonts.medium,
    color: Colors.bodyText,
  },
});

