import {
  FETCH_JOBS_REQUEST,
  FETCH_JOBS_SUCCESS,
  FETCH_JOBS_FAILURE,
  REFRESH_JOBS,
  JOBS_CURRENT_PAGE,
  EDIT_JOBS_SEARCH_QUERY,
  EDIT_JOBS_SHOW_EXTERNAL,
  FETCHING_MORE_JOBS,
  CLEAR_JOBS,
  FETCH_MY_JOBS_REQUEST,
  FETCH_MY_JOBS_SUCCESS,
  FETCH_MY_JOBS_FAILURE,
  FETCH_MY_CLOSED_JOBS_REQUEST,
  FETCH_MY_CLOSED_JOBS_SUCCESS,
  FETCH_MY_CLOSED_JOBS_FAILURE,
  MY_JOBS_CURRENT_PAGE,
  MY_CLOSED_JOBS_CURRENT_PAGE,
  EDIT_SELECTED_JOB,
  UPDATE_JOBS_SUCCESS,
} from '../constants/ActionTypes';
import { GET, POST } from '../services/Api';
import { minutesPassed } from '../helpers/time';
import { reportRequestError, reportResponsePayloadError } from '../helpers/request-errors';
import SearchParameters from '../constants/SearchParameters';

const fetchJobsRequest = {
  type: FETCH_JOBS_REQUEST,
};

const fetchJobsSuccess = (items, pagination) => {
  return {
    type: FETCH_JOBS_SUCCESS,
    payload: {
      pagination,
      items,
      fetchedAt: Date.now(),
    },
  };
};

const fetchJobsFailure = {
  type: FETCH_JOBS_FAILURE,
};

const clearJobs = {
  type: CLEAR_JOBS,
};

export const fetchJobs = (restrictToWorkorders) => async (dispatch, getState) => {
  dispatch(fetchJobsRequest);

  try {
    const { jobs, filters, offices } = getState();
    const params = {
      per_page: 10,
      page: jobs.pagination.current_page || 1,
    };

    if (!filters.scoped) {
      params.scoped = false;
    }

    if (!filters.scoped && filters.office === SearchParameters.MY_DISTRICT) {
      params.office = offices.items.map(office => office.id).join(',');
    }

    if (!filters.scoped && filters.office === SearchParameters.ENTIRE_COUNTRY) {
      params.office = filters.selectedOffices.join(',');
    }

    if(params.page === 1) {
      dispatch(clearJobs);
    }

    if (jobs.showExternal) {
      params.show_external = 1;
    }

    if (filters.service.length > 0) {
      params.service = filters.service.join(',');
    }

    if (jobs.searchQuery) {
      params.search = jobs.searchQuery;
    }

    const res = await GET('/workorders', params);
    if (!res.ok) throw res;
    try {
      const { data, ...pagination } = res.json.result;

      dispatch(fetchJobsSuccess(data, pagination));
      if (!restrictToWorkorders) {
        dispatch(fetchMyJobs());
        dispatch(fetchMyClosedJobs());
      }
    } catch (err) {
      reportResponsePayloadError(err, res);
    }
  } catch (res) {
    dispatch(fetchJobsFailure);
    reportRequestError(res, { showAlert: false });
  }
};

const fetchMyJobsRequest = {
  type: FETCH_MY_JOBS_REQUEST,
};

const fetchMyJobsSuccess = (res) => {
  return {
    type: FETCH_MY_JOBS_SUCCESS,
    payload: {
      items: res.data,
      pagination: res.meta,
    },
  };
};

const fetchMyJobsFailure = {
  type: FETCH_MY_JOBS_FAILURE,
};

export const updateJob = ({ jobId, status }) => async (dispatch, getState) => {
  const { jobs } = getState();
  const updatedJobList = jobs.items
    .map(job => {
      if (job.id === jobId) {
        return {
          ...job,
          workorder_lines: [
            {
              ...job.workorder_lines[0],
              user_interest_status: status,
            },
            ...job.workorder_lines.slice(1),
          ],
        };
      } else {
        return job;
      }
    });

  dispatch(updateJobsSuccess(updatedJobList));
};

const updateJobsSuccess = (jobsList) => {
  return {
    type: UPDATE_JOBS_SUCCESS,
    payload: jobsList,
  };
};

export const fetchMyJobs = () => async (dispatch, getState) => {
  try {
    dispatch(fetchMyJobsRequest);
    const { jobs } = getState();
    const res = await GET('/workorders/me', {
      status: 3,
      per_page: 10,
      page: jobs.myJobsPagination.current_page || 1,
    });
    if (!res.ok) throw res;
    
    const data = res.json;

    dispatch(fetchMyJobsSuccess(data));

  } catch (error) {
    dispatch(fetchMyJobsFailure);
    console.error(error);
  }
};

export const fetchMyClosedJobs = () => async (dispatch, getState) => {
  try {
    dispatch({ type: FETCH_MY_CLOSED_JOBS_REQUEST });
    const { jobs } = getState();
    const res = await GET('/workorders/me', {
      status: 4,
      per_page: 10,
      page: jobs.myClosedJobsPagination.current_page || 1,
    });
    if (!res.ok) throw res;

    const data = res.json;

    dispatch({
      type: FETCH_MY_CLOSED_JOBS_SUCCESS,
      payload: {
        items: data.data,
        pagination: data.meta,
      },
    });
  } catch (error) {
    dispatch({ type: FETCH_MY_CLOSED_JOBS_FAILURE });
    console.error(error);
  }
};

const shouldFetchJobs = state => {
  const jobs = state.jobs;

  if (!jobs) {
    return true;
  } else if (jobs.fetching) {
    return false;
  } else if (!jobs.fetchedAt) {
    return true;
  } else if (jobs.fetchError) {
    return true;
  } else {
    return minutesPassed(15, jobs.fetchedAt);
  }
};

export const fetchJobsIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchJobs(getState())) {
    return dispatch(fetchJobs());
  }
};

const refreshJobs = {
  type: REFRESH_JOBS,
}
;
const fetchingMoreJobs = {
  type: FETCHING_MORE_JOBS,
};

export const jobsCurrentPage = current_page => {
  return {
    type: JOBS_CURRENT_PAGE,
    payload: { current_page },
  };
};

export const myJobsCurrentPage = current_page => {
  return {
    type: MY_JOBS_CURRENT_PAGE,
    payload: { current_page },
  };
};

export const myClosedJobsCurrentPage = current_page => {
  return {
    type: MY_CLOSED_JOBS_CURRENT_PAGE,
    payload: { current_page },
  };
};

export const editJobsSearchQuery = searchQuery => {
  return {
    type: EDIT_JOBS_SEARCH_QUERY,
    payload: { searchQuery },
  };
};

export const editJobsShowExternal = showExternal => {
  return {
    type: EDIT_JOBS_SHOW_EXTERNAL,
    payload: { showExternal },
  };
};

export const refreshFetchJobs = () => dispatch => {
  dispatch(refreshJobs);
  dispatch(jobsCurrentPage(1));
  return dispatch(fetchJobs());
};

export const fetchMoreJobs = () => (dispatch, getState) => {
  const { pagination } = getState().jobs;

  if (!pagination.last_page || pagination.last_page === pagination.current_page) {
    return;
  }
  dispatch(fetchingMoreJobs);
  dispatch(jobsCurrentPage(pagination.current_page + 1));
  return dispatch(fetchJobs(true));
};

export const fetchMoreMyJobs = () => (dispatch, getState) => {
  const { myJobsPagination } = getState().jobs;

  if (!myJobsPagination.last_page || myJobsPagination.last_page === myJobsPagination.current_page) {
    return;
  }
  dispatch(fetchingMoreJobs);
  dispatch(myJobsCurrentPage(myJobsPagination.current_page + 1));
  return dispatch(fetchMyJobs());
};

export const fetchMoreClosedJobs = () => (dispatch, getState) => {
  const { myClosedJobsPagination } = getState().jobs;

  if (!myClosedJobsPagination.last_page || myClosedJobsPagination.last_page === myClosedJobsPagination.current_page) {
    return;
  }
  dispatch(fetchingMoreJobs);
  dispatch(myClosedJobsCurrentPage(myClosedJobsPagination.current_page + 1));
  return dispatch(fetchMyClosedJobs());
};


const editSelectedJob = changeset => {
  return {
    type: EDIT_SELECTED_JOB,
    payload: { changeset },
  };
};


export const updateJobRiskConsent = (workorderID) => async (dispatch) => {

  try {
    const res = await POST('/workorders/accept-risk/me', { id: workorderID });

    if (!res.ok) throw res;
    const data = res.json;

    dispatch(editSelectedJob({
      workorderID,
      data,
    }));

  } catch (res) {
    reportRequestError(res, { showAlert: false });
  }
};
