import React from 'react';
import { RefreshControl, View, SectionListData, SectionList } from 'react-native';
import { useIsFocused, useScrollToTop } from '@react-navigation/native';
import { compose } from 'redux';
import { connect } from 'react-redux';

import ListItemSeparator from '../components/ListItemSeparator';
import ListItem from '../components/ListItem';
import SectionHeader from '../components/SectionHeader';
import { fetchJobsIfNeeded, refreshFetchJobs, fetchMoreJobs } from '../actions/jobs';
import JobsListHeaderContainer from '../containers/JobsListHeaderContainer';
import JobsListHeaderStatusContainer from './JobsListHeaderStatusContainer';
import withLoader from '../components/withLoader';
import withFetchError from '../components/withFetchError';
import withFetch from '../components/withFetch';
import withNotificationClearing from '../components/withNotificationClearing';
import NotificationResourceTypes from '../constants/NotificationResourceTypes';
import { JobItem, JobRequest, Navigation } from '../constants/types';
import { UserInterestStatus } from '../constants/Statuses';

type ModifiedJobItem = {
  id: number;
  workOrderId: number;
  title: string;
  subtitle: string;
  subsubtitle?: string;
  date: string;
  attention: boolean;
  applied: boolean;
}

interface SectionHeader {
  section: SectionListData<Section>
}

type Section = {
  title?: string;
  data: ModifiedJobItem[];
}

type Props = {
  jobs: JobRequest;
  jobCount: number;
  hasSearched: boolean;
  fetchMoreJobs: typeof fetchMoreJobs;
  isFocused: boolean;
  refreshFetchJobs: typeof refreshFetchJobs;
  navigation: Navigation;
}

const notificationType = NotificationResourceTypes.Job;

const _renderListHeader = () => {
  return (
    <View>
      <JobsListHeaderContainer />
      <JobsListHeaderStatusContainer />
    </View>
  );
};

const _renderSectionHeader = ({ section }) => {

  const { title, data } = section; 
  if (!data.length) {
    return null;
  }

  return (
    <SectionHeader title={ title } />
  );
};

function JobsContainer(props: Props) {
  const ref = React.useRef(null);
  const isFocused = useIsFocused();
  useScrollToTop(ref);

  const _onEndReached = () => {
    const { fetchMoreJobs, jobs } = props;

    if (isFocused && !jobs.fetching) {
      fetchMoreJobs();
    }
  };

  const _onRefresh = () => {
    const { refreshFetchJobs } = props;

    refreshFetchJobs();
  };

  const _renderItem = ({ item }: { item: ModifiedJobItem }) => {
    return (
      <ListItem
        id={ item.workOrderId }
        title={ item.title }
        subtitle={ item.subtitle }
        subsubtitle={ item.subsubtitle }
        date={ item.date }
        attention={ item.attention }
        onPress={ _onPress(item.id, item.title) }
        applied={ item.applied }
        approved={ item.approved }
      />
    );
  };

  const _onPress = (id: number, title: string) => () => {
    props.navigation.push('Job', { id, title });
  };
  const { jobs, jobCount, hasSearched, notifications } = props;

  const addressWithoutNumber = (address: string) => {
    const [trimmed] = (address?.match(/^[\D]*[^\s\d]/) ?? []);
    return trimmed;
  };

  const listSections = [{
    title: hasSearched ? `Sökresultat - ${ jobCount } uppdrag` : 'Uppdragslista',
    data: jobs.fetching && !jobs.refreshing && !jobs.fetchingMoreJobs ? [] : jobs.items.map((item: JobItem) => {

      return {
        id: item.id,
        workOrderId: item.workorder_number,
        title: item.title,
        subtitle: item.office.name,
        subsubtitle: item.client ? `${ addressWithoutNumber(item.client?.address) }, ${ item.client?.postal_code } ${ item.client?.city }` : null,
        date: item.created_at,
        attention: !!notifications.items.find(i => i.data.resource_type === notificationType && i.data.resource_id === item.id),
        applied: item.workorder_lines[0]?.user_interest_status === UserInterestStatus.APPLIED,
        approved: item.workorder_lines[0]?.user_interest_status === UserInterestStatus.APPROVED,
      };
    }),
  }];
  return (
    <SectionList
      ref={ ref }
      contentContainerStyle={ { paddingBottom: 24 } }
      keyExtractor={ item => `${ item.id }` }
      sections={ listSections }
      renderItem={ _renderItem }
      renderSectionHeader={ _renderSectionHeader }
      ItemSeparatorComponent={ ListItemSeparator }
      stickySectionHeadersEnabled={ false }
      ListHeaderComponent={ _renderListHeader }
      onEndReached={ _onEndReached }
      onEndReachedThreshold={ 0.75 }
      refreshControl={
        <RefreshControl
          refreshing={ jobs.refreshing }
          onRefresh={ _onRefresh }
        />
      }
    />
  );
}

const mapState = state => {
  const { jobs, notifications } = state;

  return {
    jobs,
    jobCount: jobs.pagination.total,
    hasSearched: !!jobs.searchQuery,
    notifications,
  };
};

const mapDispatch = dispatch => {
  return {
    fetch: () => dispatch(fetchJobsIfNeeded()),
    refreshFetchJobs: () => dispatch(refreshFetchJobs()),
    fetchMoreJobs: () => dispatch(fetchMoreJobs()),
  };
};

export default compose(
  connect(mapState, mapDispatch),
  withNotificationClearing(notificationType),
  withLoader,
  withFetchError,
  withFetch
)(JobsContainer);
