import React, { useEffect } from 'react';
import MainStack from './MainStack';

import { createStackNavigator } from '@react-navigation/stack';
import { connect } from 'react-redux';
import RegisterScreen from '../screens/RegisterScreen';
import LogInScreen from '../screens/LogInScreen';
import { checkLoggedIn } from '../actions/auth';
import { Platform } from 'react-native';
const Stack = createStackNavigator();

function MainNavigator({ auth, checkLoggedIn }) {
  useEffect(() => {
    if (Platform.OS === 'web') {
      checkLoggedIn();
    }
  }, []);

  return (
    <Stack.Navigator>
      { auth.accessToken ? (
        <Stack.Screen
          name={ 'Main' }
          component={ MainStack }
          options={ {
            headerShown: false,
          } }
        />
      ) : (
        <>
          <Stack.Screen
            name={ 'LogIn' }
            component={ LogInScreen }
            options={ {
              headerShown: false,
              headerBackTitle: 'Logga in',
            } }
          />
          <Stack.Screen
            name={ 'Register' }
            component={ RegisterScreen }
            options={ {
              headerShown: false,
            } }
          />
        </>
      )
      }

    </Stack.Navigator>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { checkLoggedIn })(MainNavigator);
