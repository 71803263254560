import React from 'react';
import { View, StyleSheet } from 'react-native';
import Text from './Text';
import { PrimaryButton, InvertedButton } from './Button';
import Fonts from '../constants/Fonts';
import Colors from '../constants/Colors';
import moment from 'moment';

type Props = {
  id: number;
  workorderId: number;
  title: string;
  subTitle: string;
  closed: boolean;
  date: string;
  lastTimeReport: string | null;
  onPress: (id: number, title: string) => void;
  needsConfirmation: boolean;
}

const OngoingJobItem = ({ id, workorderId, title, subTitle, closed, date, lastTimeReport, onPress, needsConfirmation }: Props) => {
  return (
    <View style={ styles.container }>
      <View style={ styles.content }>
        <View style={ { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 8, alignItems: 'center' } }>
          <Text style={ styles.id }>
            { workorderId }
          </Text>
          {/*{ needsConfirmation && !closed && <View style={ styles.appliedTag }>*/}
          {/*  <Text style={ styles.appliedTagText }>Kräver bekräftelse</Text>*/}
          {/*</View>}*/}
        </View>
        <Text style={ styles.title }>
          { title }
        </Text>
        <Text style={ styles.subTitle }>
          { subTitle }
        </Text>
        <Text style={ styles.date }>
          { `Tilldelad: ${ moment(date).format('YYYY-MM-DD HH:mm') }` }
        </Text>
        <Text style={ styles.date }>
          { 
            lastTimeReport ?
              `Din senaste tidrapport skickades in ${ moment(lastTimeReport).format('YYYY-MM-DD HH:mm') }` :
              'Ingen tidrapport inskickad ännu'
          }
        </Text>
      </View>
      { closed ?
        <InvertedButton onPress={ () => onPress(id, title) } title={ 'Visa sammanställning' }/> :
        <PrimaryButton onPress={ () => onPress(id, title) } title={ 'Öppna uppdrag' }/>
      }
      { !!closed &&
        <View style={ styles.finishedTag }>
          <Text style={ styles.finishedTagText }>
            Avslutad
          </Text>
        </View>
      }
    </View>
  );
};

export default OngoingJobItem;

const styles = StyleSheet.create({
  container: {
    padding: 24,
    borderBottomWidth: 1,
    borderColor: Colors.border,
    backgroundColor: Colors.veryWhite,
    marginBottom: 24,
  },
  content: {
    marginBottom: 24,
  },
  id: {
    fontFamily: Fonts.medium,
  },
  title: {
    fontFamily: Fonts.emphasis,
    fontSize: 20,
    marginBottom: 8,
  },
  subTitle: {
    marginBottom: 8,
    color: Colors.secondaryText,
  },
  date: {
    fontSize: 14,
    color: Colors.secondaryText,
  },
  finishedTag: {
    position: 'absolute',
    top: 12,
    right: 12,
    paddingVertical: 4,
    paddingHorizontal: 16,
    borderRadius: 8,
    backgroundColor: Colors.primaryBackground,
  },
  finishedTagText: {
    fontFamily: Fonts.emphasis,
    fontSize: 15,
    color: Colors.veryWhite,
  },
  appliedTag: {
    paddingHorizontal: 12,
    borderRadius: 8,
    backgroundColor: Colors.tintColor,
  },
  appliedTagText: {
    fontFamily: Fonts.emphasis,
    fontSize: 12,
    color: Colors.veryWhite,
  },
});
