import React, { ReactElement } from 'react';
import { format } from 'date-fns';

interface Props {
  minimumDate?: Date;
  maximumDate?: Date;
  value: string;
  onChange: (value: any) => void;
}

function DateInput(props: Props): ReactElement {
  const { minimumDate, maximumDate, ...moreProps } = props;

  return <input
    type={ 'date' }
    min={ minimumDate && format(minimumDate, 'yyyy-MM-dd') }
    max={ maximumDate && format(maximumDate, 'yyyy-MM-dd') }
    pattern={ 'd{4}-d{2}-d{2}' }
    { ...moreProps }
  />;
}

export default DateInput;
