import * as React from 'react';
import {
  View,
  SectionList, Platform,
} from 'react-native';
import Loader from '../components/Loader';
import { GET } from '../services/Api';
import { reportRequestError } from '../helpers/request-errors';
import ListItemSeparator from '../components/ListItemSeparator';
import ListItem from '../components/ListItem';
import SectionHeader from '../components/SectionHeader';
import { useScrollToTop } from '@react-navigation/native';
import { connect } from 'react-redux';
import Constants from 'expo-constants';

function InformationPagesContainer(props) {
  const [loading, setLoading] = React.useState(true);
  const [categories, setCategories] = React.useState({ json: [] });
  const [userData, setUserData] = React.useState(props.userData);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    fetchData();
  }, []);

  const ref = React.useRef(null);

  useScrollToTop(ref);

  const _renderSectionHeader = ({ section: { title } }) => {
    return (
      <SectionHeader title={ title } />
    );
  };

  async function fetchData() {
    const result = await _fetchInformationPages();

    setLoading(false);
    setCategories(result);
  }

  const _renderItem = ({ item, index, section }) => {
    return (
      <ListItem
        title={ item.title }
        onPress={ _onPress(item.permalink) }
      />
    );
  };

  const _onPress = (url) => () => {
    props.navigation.push('InformationPage', { url, userData: userData });
  };
  
  const _fetchInformationPages = async () => {
    const wpUrl = Constants.expoConfig.extra.WP_URL;
    try {
      const url = `${ wpUrl }/wp-json/wp/v2/information_page_category`;
      
      const res = await GET(url, null, true);
      if (!res.ok) throw res;
  
      return res;
    } catch (res) {
      reportRequestError(res, { showAlert: true });
    }

    return false;
  };

  const listSections = categories?.json?.map((category) => {
    return {
      title: category.name,
      data: category.posts,
    };
  }) || [];

  return (
    <View style={ Platform.OS === 'web' && { height: '100%' } }>
      {
        loading ?
          <Loader></Loader>
          :
          <SectionList
            ref={ ref }
            contentContainerStyle={ { paddingBottom: 24 } }
            keyExtractor={ item => `${ item.id }` }
            sections={ listSections }
            renderItem={ _renderItem }
            renderSectionHeader={ _renderSectionHeader }
            ItemSeparatorComponent={ ListItemSeparator }
            stickySectionHeadersEnabled={ false }
          />
      }
    </View>
  );
}

const mapState = state => {
  const { user } = state;

  return {
    userData: user.data,
  };
};

export default connect(mapState)(InformationPagesContainer);
