import React, { useState, useEffect } from 'react';
import { FlatList, View, Platform } from 'react-native';
import { RefreshControl } from 'react-native';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as WebBrowser from 'expo-web-browser';
import { useScrollToTop } from '@react-navigation/native';
import withFetch from '../components/withFetch';

import Colors from '../constants/Colors';
import Loader from '../components/Loader';

import Card from '../components/Card';
import { fetchJobsIfNeeded, refreshFetchJobs } from '../actions/jobs';
import { fetchMessagesIfNeeded, refreshFetchMessages } from '../actions/messages';
import { fetchPayslipsIfNeeded, fetchPayslipIfNeeded, refreshFetchPayslips } from '../actions/payslips';
import MessageContainer from './MessageContainer';
import JobContainer from './JobContainer';
import withFetchError from '../components/withFetchError';
import { monthString } from '../helpers/time';
import { fetchNotificationsIfNeeded, markNotificationRead, fetchNotifications } from '../actions/notifications';
import NotificationResourceTypes from '../constants/NotificationResourceTypes';
import UserInfo from '../components/UserInfo';
function HomeContainer(props) {
  const [refreshing, setRefreshing] = useState(false);
  const ref = React.useRef(null);
  useScrollToTop(ref);
  const _onWillFocus = () => {
    const {
      jobNotification,
      messageNotification,
      payslipNotification,
      markNotificationRead,
    } = props;

    const notifications = [jobNotification, payslipNotification, messageNotification].filter(n => n);

    notifications.forEach(notification => {
      markNotificationRead(notification.id);
    });
  };

  const _renderItem = ({ item }) => {
    return (
      <Card
        header={ item.header }
        image={ item.image }
        title={ item.title }
        text={ item.text }
        date={ item.date }
        actionTitle={ item.actionTitle }
        onPress={ item.onPress }
        headerStyles={ item.headerStyles }
      />
    );
  };

  const _onRefresh = async () => {
    const { refresh } = props;
    setRefreshing(true);
    await refresh();
    setRefreshing(false);
  };

  const _onJobPress = (id, title) => () => {
    props.navigation.navigate('Modal', {
      childComponent: JobContainer,
      childComponentProps: { id },
      title,
    });
  };

  const _onPayslipPress = id => async () => {
    const { fetchPayslipIfNeeded } = props;

    fetchPayslipIfNeeded(id).then(res => {
      WebBrowser.openBrowserAsync(res.temp_url);
    });
  };

  const _onMessagePress = (id, title) => () => {
    props.navigation.push('Modal', {
      childComponent: MessageContainer,
      childComponentProps: { id },
      title,
    });
  };

  const _onReportPress = (userData) => () => {
    props.navigation.navigate('ProfileStack', {
      screen: 'InformationPages',
      initial: false,
    });
  };

  useEffect(() => {
    const willFocusSubscription = props.navigation.addListener('focus', _onWillFocus);

    return willFocusSubscription;
  });

  const { message, payslip, job, userData, loading } = props;
  const listData = [];
  if (message) {
    const title = message.title ? message.title.rendered : '';
    listData.push({
      header: 'Senaste meddelandet',
      image: message.image,
      title,
      text: `Veteranpoolen ${ !message.office ? 'Sverige' : message.office.join(', ') }`,
      date: message.date,
      actionTitle: 'Läs meddelandet',
      onPress: _onMessagePress(message.id, title),
    });
  }

  if (job) {
    listData.push({
      header: 'Nytt uppdrag i ditt område',
      title: job.title,
      text: job.office,
      date: job.date,
      actionTitle: 'Läs mer och ansök',
      onPress: _onJobPress(job.id, job.title),
    });
  }

  if (payslip) {
    listData.push({
      header: 'Din senaste lönespecifikation',
      title: `${ monthString(payslip.month) } ${ payslip.year }`,
      text: 'Lönespecifikation',
      date: payslip.created_at,
      actionTitle: 'Öppna din lönespecifikation',
      onPress: _onPayslipPress(payslip.id),
    });
  }
  listData.push({
    header: 'Arbetsmiljö',
    title: 'Anmäl olycka eller tillbud, samt läs mer om riskbedömningar och hur du jobbar säkert',
    text: '',
    date: '',
    actionTitle: 'Gå till',
    headerStyles: {
      backgroundColor: Colors.rustRed,
    },
    onPress: _onReportPress(userData),
  });
  return (
    <View style={ Platform.OS === 'web' && { height: '100%' } }>
      <UserInfo name={ `${ userData.first_name } ${ userData.last_name }` } employeeNumber={ userData.number } />
      { loading ?
        <Loader /> :
        <FlatList
          ref={ ref }
          contentContainerStyle={ { paddingBottom: 96 } }
          keyExtractor={ item => item.header }
          data={ listData }
          renderItem={ _renderItem }
          refreshControl={
            <RefreshControl
              refreshing={ refreshing }
              onRefresh={ _onRefresh }
            />
          }
        />
      }
    </View>
  );
}

const mapState = state => {
  const { messages, payslips, jobs, notifications, offices, user } = state;

  const job = jobs.items.filter(j => j.type === 'internal')[0];
  const message = messages.items[0];
  const payslip = payslips.items[0];
  const getNotification = (type, id) => {
    return notifications.items.find(i => i.data.resource_type === type && i.data.resource_id === id);
  };
  const fetchers = [messages, payslips, offices, jobs];

  return {
    loading: fetchers.some(f => f.fetching && !f.refreshing),
    fetchError: fetchers.some(f => f.fetchError),
    job,
    message,
    payslip,
    userData: user.data,
    jobNotification: job && getNotification(NotificationResourceTypes.Job, job.id),
    messageNotification: message && getNotification(NotificationResourceTypes.Message, message.id),
    payslipNotification: payslip && getNotification(NotificationResourceTypes.Payslip, payslip.id),
  };
};

const mapDispatch = dispatch => {
  return {
    fetchPayslipIfNeeded: id => dispatch(fetchPayslipIfNeeded(id)),
    markNotificationRead: id => dispatch(markNotificationRead(id)),
    fetch: () => {
      dispatch(fetchPayslipsIfNeeded());
      dispatch(fetchJobsIfNeeded());
      dispatch(fetchMessagesIfNeeded());
      dispatch(fetchNotificationsIfNeeded());
    },
    refresh: () => {
      return Promise.all([
        dispatch(refreshFetchJobs()),
        dispatch(refreshFetchPayslips()),
        dispatch(refreshFetchMessages()),
        dispatch(fetchNotifications()),
      ]);
    },
  };
};

export default compose(
  connect(mapState, mapDispatch),
  withFetchError,
  withFetch
)(HomeContainer);
