import {
  FETCH_JOBS_REQUEST,
  FETCH_JOBS_SUCCESS,
  FETCH_JOBS_FAILURE,
  REFRESH_JOBS,
  JOBS_CURRENT_PAGE,
  EDIT_JOBS_SEARCH_QUERY,
  EDIT_JOBS_SHOW_EXTERNAL,
  FETCHING_MORE_JOBS,
  CLEAR_JOBS,
  FETCH_MY_JOBS_SUCCESS,
  FETCH_MY_JOBS_REQUEST,
  FETCH_MY_JOBS_FAILURE,
  FETCH_MY_CLOSED_JOBS_SUCCESS,
  FETCH_MY_CLOSED_JOBS_REQUEST,
  FETCH_MY_CLOSED_JOBS_FAILURE,
  MY_JOBS_CURRENT_PAGE,
  MY_CLOSED_JOBS_CURRENT_PAGE,
  EDIT_SELECTED_JOB,
  UPDATE_JOBS_SUCCESS,
} from '../constants/ActionTypes';

export default (state = {
  fetching: false,
  fetchError: false,
  refreshing: false,
  searchQuery: '',
  showExternal: false,
  pagination: {},
  items: [],
  fetchedAt: null,
  myJobs: [],
  myClosedJobs: [],
  myJobsPagination: {},
  myClosedJobsPagination: {},
}, action) => {
  switch (action.type) {
    case FETCH_JOBS_REQUEST:
      return Object.assign({}, state, {
        fetching: true,
        fetchError: false,
      });
    case FETCH_JOBS_SUCCESS:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: false,
        refreshing: false,
        fetchingMoreJobs: false,
        pagination: action.payload.pagination,
        items: action.payload.pagination.current_page === 1 ?
          [...action.payload.items] :
          [...state.items, ...action.payload.items],
        fetchedAt: action.payload.fetchedAt,
      });
    case FETCH_JOBS_FAILURE:
      return Object.assign({}, state, {
        fetching: false,
        fetchError: true,
      });
    case UPDATE_JOBS_SUCCESS:
      return Object.assign({}, state, {
        items: action.payload,
      });
    case REFRESH_JOBS:
      return Object.assign({}, state, {
        refreshing: true,
      });
    case FETCHING_MORE_JOBS:
      return Object.assign({}, state, {
        fetchingMoreJobs: true,
      });
    case JOBS_CURRENT_PAGE:
      return Object.assign({}, state, {
        pagination: {
          ...state.pagination,
          current_page: action.payload.current_page,
        },
      });
    case MY_JOBS_CURRENT_PAGE:
      return Object.assign({}, state, {
        myJobsPagination: {
          ...state.myJobsPagination,
          current_page: action.payload.current_page,
        },
      });
    case MY_CLOSED_JOBS_CURRENT_PAGE:
      return Object.assign({}, state, {
        myClosedJobsPagination: {
          ...state.myClosedJobsPagination,
          current_page: action.payload.current_page,
        },
      });
    case EDIT_JOBS_SHOW_EXTERNAL:
      return Object.assign({}, state, {
        showExternal: action.payload.showExternal,
      });
    case EDIT_JOBS_SEARCH_QUERY:
      return Object.assign({}, state, {
        searchQuery: action.payload.searchQuery,
      });
    case CLEAR_JOBS:
      return Object.assign({}, state, {
        items: [],
      });
    case FETCH_MY_JOBS_REQUEST:
      return state;
    case FETCH_MY_JOBS_SUCCESS:
      return Object.assign({}, state, {
        myJobs: action.payload.pagination.current_page === 1 ?
          [...action.payload.items] :
          [...state.myJobs, ...action.payload.items],
        myJobsPagination: action.payload.pagination,
      });
    case FETCH_MY_JOBS_FAILURE:
      return state;
    case FETCH_MY_CLOSED_JOBS_REQUEST:
      return state;
    case FETCH_MY_CLOSED_JOBS_SUCCESS:
      return Object.assign({}, state, {
        myClosedJobs: action.payload.pagination.current_page === 1 ?
          [...action.payload.items] :
          [...state.myClosedJobs, ...action.payload.items],
        myClosedJobsPagination: action.payload.pagination,
      });
    case FETCH_MY_CLOSED_JOBS_FAILURE:
      return state;
    case EDIT_SELECTED_JOB:
      let workorderID = action.payload.changeset.workorderID;
      let workorder = action.payload.changeset.data.data;

      let idx = state.myJobs.findIndex((j) => {
        return j.id === workorderID;
      });

      state.myJobs[idx] = {...state.myJobs[idx], ...workorder};
    default:
      return state;
  }
}
