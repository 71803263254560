import {
  FETCH_BANKID_TOKEN_REQUEST,
  FETCH_BANKID_TOKEN_SUCCESS,
  FETCH_BANKID_TOKEN_FAILURE,
} from '../constants/ActionTypes';
import { GET } from '../services/Api';
import { reportRequestError } from '../helpers/request-errors';

const fetchBankidTokenRequest = {
  type: FETCH_BANKID_TOKEN_REQUEST,
};

const fetchBankidTokenSuccess = res => {
  return {
    type: FETCH_BANKID_TOKEN_SUCCESS,
    payload: {
      orderRef: res.result.orderRef,
      qrStartToken: res.result.qrStartToken,
      qrStartSecret: res.result.qrStartSecret,
      autoStartToken: res.result.autoStartToken,
      fetchedAt: Date.now(),
    },
  };
};

const fetchBankidTokenFailure = {
  type: FETCH_BANKID_TOKEN_FAILURE,
};

export const fetchBankidToken = (useQRCode) => async (dispatch, getState) => {
  const { testMode } = getState().bankidToken;

  dispatch(fetchBankidTokenRequest);

  try {
    let params;

    if (testMode) {
      params = {
        endUserIp: '89.255.243.132',
        personalNumber: '198001016636',
      };
    } else if (useQRCode) {
      params = {
        bankIdMessage: 'VmV0ZXJhbnBvb2xlbiBpbmxvZ2duaW5n',
      };
    }
    
    const res = await GET('/auth/login', params);

    if (!res.ok) throw res;

    dispatch(fetchBankidTokenSuccess(res.json));
  } catch (res) {
    dispatch(fetchBankidTokenFailure);
    reportRequestError(res, { showAlert: false });
  }
}
