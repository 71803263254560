import { View, Image, Text } from 'react-native';
import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';

const QRCodeGenerator = ({ qrData }: { qrData: string }) => {
  const [qrImage, setQRImage] = useState('' as string);

  useEffect(() => {
    const generateQRCode = async () => {
      try {
        const url = await QRCode.toDataURL(qrData, { margin: 2 });
        setQRImage(url);
      } catch (error) {
        console.error('Error generating QR code:', error);
      }
    };

    generateQRCode();
  }, [qrData]);

  return (
    <View style={ { width: '100%', height: 250, marginBottom: 24 } }>
      <Image style={ { width: '100%', height: '100%', resizeMode: 'contain' } } source={ { uri: qrImage } }/>
    </View>
  );
};

export default QRCodeGenerator;
