import React from 'react';
import {
  ActivityIndicator,
  Image,
  StyleSheet,
  View,
  TouchableWithoutFeedback,
  TextInput,
  KeyboardAvoidingView,
  Keyboard,
  ScrollView,
  Platform,
} from 'react-native';
import { connect } from 'react-redux';
import * as Linking from 'expo-linking';
import { Video } from 'expo-av';
import * as WebBrowser from 'expo-web-browser';

import { startBankidAuth, finishBankidAuth, doTestLogin } from '../actions/bankid';
import { returnFromBankidApp } from '../actions/bankid-app';
import { PrimaryButton, SecondaryButton } from '../components/Button';
import { doManualLogin, hideManualLoginError } from '../actions/login';
import Text from '../components/Text';
import Fonts from '../constants/Fonts';
import LogoFile from '../assets/images/logo-white.png';
import VideoFile from '../assets/images/login-video.mp4';
import BankidIconFile from '../assets/images/bankid-logo.png';
import OfflineWarning from '../components/OfflineWarning';
import Colors from '../constants/Colors';
import QRCode from 'qrcode';
import BankIDQRCode from '../components/BankIDQRCode';
const TestLoginDialog = Platform.OS !== 'web' && require('../components/TestLoginDialog').default;

class LogInContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logoClickCount: 0,
      testLoginDialogVisible: false,
      showUsernamePasswordLogin: false,
      email: '',
      password: '',
      loginAttempt: 0,
    };
  }

  componentDidMount() {
    this.listener = Linking.addEventListener('url', this._handleOpenURL);
  }

  componentWillUnmount() {
    this.listener && this.listener.remove();
  }

  render() {
    const {
      fetching,
      collectMessage,
      tokenFetchError,
      collectFetchError,
      tokenTestMode,
      isConnected,
      fetchingManualLogin,
      fetchingManualError,
      bankidResolved,
      qrData,
    } = this.props;
    const {
      testLoginDialogVisible,
      showUsernamePasswordLogin,
      loginAttempt,
    } = this.state;

    const isMobileBrowser = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const loginButton = (
      <>
        {
          Platform.OS === 'web' ?
            (
              <View>
                { qrData && <BankIDQRCode qrData={ qrData }  /> }
                { !isMobileBrowser && <PrimaryButton style={ [styles.button, styles.loginButton] }
                  title={ 'Logga in' }
                  activeOpacity={ 0.85 }
                  icon={ fetching ?
                    <ActivityIndicator color={ '#fff' } /> :
                    <Image style={ styles.buttonIconBankidImage } source={ BankidIconFile } /> }
                  onPress={ () => this._onLoginPress(true) } /> }
                { isMobileBrowser && <PrimaryButton style={ [styles.button, { marginTop: 24 }] }
                  title={ 'Starta BankID på den här enheten' }
                  activeOpacity={ 0.85 }
                  icon={ fetching ?
                    <ActivityIndicator color={ '#fff' } /> :
                    <Image style={ styles.buttonIconBankidImage } source={ BankidIconFile } /> }
                  onPress={ () => this._onLoginPress(false) } /> }
              </View>
            ) : (
              <PrimaryButton style={ styles.button }
                title={ 'Logga in med BankID' }
                activeOpacity={ 0.85 }
                icon={ fetching || !bankidResolved ?
                  <ActivityIndicator color={ '#fff' } /> :
                  <Image style={ styles.buttonIconBankidImage } source={ BankidIconFile } /> }
                onPress={ () => this._onLoginPress(false) } />
            )
        }
      </>
    );

    return (
      <View style={ styles.container }>
        {
          Platform.OS !== 'web' &&
          <TestLoginDialog
            visible={ testLoginDialogVisible }
            onSubmit={ this._onTestLoginDialogSubmit }
            onCancel={ this._onTestLoginDialogCancel }
          />
        }
        {
          Platform.OS !== 'web' ?
            <Video
              style={ styles.video }
              source={ VideoFile }
              shouldPlay={ true }
              isLooping={ true }
              resizeMode={ 'cover' } /> :
            <View style={ { ...StyleSheet.absoluteFill, backgroundColor: Colors.headerBackground, paddingTop: 128, alignItems: 'center' } }>
              <Image
                style={ { width: 500, minHeight: 40 } }
                source={ { uri: LogoFile } }
              />
            </View>
        }
        <ScrollView contentContainerStyle={ styles.flex }>
          <KeyboardAvoidingView style={ styles.flex } behavior={ 'padding' }>
            <View style={ styles.contentContainer }>
              <View style={ styles.logoContainer }>
                <TouchableWithoutFeedback onPress={ this._onLogoPress }>
                  <Image
                    style={ styles.logo }
                    source={ LogoFile }
                  />
                </TouchableWithoutFeedback>
              </View>
              <View>
                <Text style={ styles.text }>
                  Välkommen till Vappen!
                </Text>
                <Text style={ styles.text }>
                  Få information om lediga jobb, meddelanden från Veteranpoolen och se din lönespecifikation!
                </Text>
              </View>
              <View>
                {false && tokenTestMode &&
                  <SecondaryButton title={ 'Trigger BankID collect' } onPress={ this._onFinishPress } />
                }

                <View style={ styles.bankidFeedback }>
                  { loginAttempt >= 5 ? (
                    <Text style={ styles.bankidFeedbackText }>
                      Något gick fel, Försök igen. Om du trots flera försök inte kan logga in, kontakta vår support.
                    </Text>
                  ) : tokenFetchError ? (
                    <Text style={ styles.bankidFeedbackText }>
                      Kunde inte påbörja verifiering med BankID, försök igen.
                    </Text>
                  ) : collectFetchError ? (
                    <Text style={ styles.bankidFeedbackText }>
                      Vi hittade inte dig, är du ny hos oss?
                    </Text>
                  ) : collectMessage ? (
                    <Text style={ styles.bankidFeedbackText }>
                      {collectMessage}
                    </Text>
                  ) : null}
                </View>
                { showUsernamePasswordLogin ?
                  (
                    <View>
                      <TextInput
                        underlineColorAndroid={ 'transparent' }
                        style={ styles.textInput }
                        type={ 'email' }
                        keyboardType={ 'email-address' }
                        placeholder={ 'E-post' }
                        value={ this.state.email }
                        onChangeText={ this._onEmailChange }
                        autoCapitalize={ 'none' }
                      />
                      <TextInput
                        underlineColorAndroid={ 'transparent' }
                        style={ styles.textInput }
                        type={ 'password' }
                        secureTextEntry={ true }
                        placeholder={ 'Lösenord' }
                        value={ this.state.password }
                        onChangeText={ this._onPasswordChange }
                        autoCapitalize={ 'none' }
                      />
                      <PrimaryButton style={ [styles.button, styles.loginButton] }
                        title={ 'Logga in' }
                        activeOpacity={ 0.85 }
                        icon={ fetchingManualLogin && <ActivityIndicator color={ '#fff' } /> }
                        onPress={ this._onUsernamePasswordLoginPress } />
                      {fetchingManualError && (
                        <View style={ styles.footer }>
                          <Text style={ styles.footerErrorText }>
                              Inloggningen misslyckades, säkerställ att du har angett rätt uppgifter.
                          </Text>
                        </View>
                      )}
                      <View style={ styles.footer }>
                        <Text onPress={ this._onUsernamePasswordClick } style={ styles.footerToggleText }>
                          Logga in med BankID
                        </Text>
                      </View>
                    </View>
                  ) : (
                    <View>
                      <View style={ styles.buttonContainer }>
                        {
                          loginAttempt >= 5 && (
                            <PrimaryButton style={ [styles.button, { marginBottom: 16 }] }
                              title={ 'Kontakta vår support' }
                              activeOpacity={ 0.85 }
                              onPress={ this._onSupportPress }
                            />
                          )
                        }
                        {isConnected ? loginButton : (
                          <OfflineWarning style={ { marginTop: 12 } } />
                        )}
                      </View>
                      <View style={ styles.footer }>
                        <Text onPress={ this._onUsernamePasswordClick } style={ styles.footerToggleText }>
                          Logga in med e-post och lösenord
                        </Text>
                      </View>
                    </View>
                  )}

                <View style={ styles.footer }>
                  <Text style={ styles.footerText }>
                    Genom att använda denna applikation godkänner du
                    våra <Text style={ [styles.footerText, styles.footerTextLink] } onPress={ this._onTermsPress }>Användarvillkor</Text> och <Text style={ [styles.footerText, styles.footerTextLink] } onPress={ this._onPolicyPress }>Integritetspolicy</Text>.
                  </Text>
                </View>
                <View style={ styles.bottomContainer }></View>
              </View>

            </View>
          </KeyboardAvoidingView>
        </ScrollView>
      </View>
    );
  }

  _onUsernamePasswordClick = () => {
    this.setState({ showUsernamePasswordLogin: !this.state.showUsernamePasswordLogin });
  }

  _onEmailChange = (input) => {
    const { hideManualLoginError } = this.props;
    hideManualLoginError();
    this.setState({email: input});
  }
  _onPasswordChange = (input) => {
    const { hideManualLoginError } = this.props;
    hideManualLoginError();
    this.setState({ password: input });
  }

  _handleOpenURL = ({ url }) => {
    const { returnFromBankidApp, finishBankidAuth } = this.props;
    const { queryParams } = Linking.parse(url);

    // returning from bankid (ios)
    if (queryParams.bankidSuccess) {
      returnFromBankidApp();
      finishBankidAuth();
    }
  }

  _onLogoPress = () => {
    const { logoClickCount } = this.state;
    const current = logoClickCount + 1;

    this.setState({ logoClickCount: current });

    if (current % 10 === 0) {
      this.setState({ testLoginDialogVisible: true });
    }
  }

  _onSupportPress = async () => {
    await Linking.openURL('mailto:support@veteranpoolen.se');
  }

  _onUsernamePasswordLoginPress = () => {
    const { doManualLogin } = this.props;
    const { email, password } = this.state;
    Keyboard.dismiss();
    doManualLogin({ email, password });
  }

  _onLoginPress = (useQrCode) => {
    const { fetching, startBankidAuth, bankidResolved } = this.props;
    const { loginAttempt } = this.state;

    if (fetching || !bankidResolved) {
      return;
    }
    this.setState({ loginAttempt: loginAttempt + 1 });
    startBankidAuth(useQrCode);
  }

  _onFinishPress = () => {
    const { finishBankidAuth } = this.props;
    finishBankidAuth();
  }

  _onTermsPress = () => {
    WebBrowser.openBrowserAsync(
      'https://veteranpoolen.se/anvandarvillkor'
    );
  }

  _onPolicyPress = () => {
    WebBrowser.openBrowserAsync(
      'https://veteranpoolen.se/integritetspolicy'
    );
  }

  _onTestLoginDialogSubmit = password => {
    const { doTestLogin } = this.props;

    if (password.toLowerCase() === 'vappentest') {
      this.setState({ testLoginDialogVisible: false });
      doTestLogin();
    }
  }

  _onTestLoginDialogCancel = () => {
    this.setState({ testLoginDialogVisible: false });
  }
}

const styles = StyleSheet.create({
  flex: {
    flex: 1
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 24,
    borderWidth: 1,
  },
  video: {
    ...StyleSheet.absoluteFillObject,
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  logoContainer: {
    flexGrow: 0,
    alignItems: 'center',
    marginTop: 40,
  },
  logo: {
    width: '90%',
    resizeMode: 'contain',
  },
  text: {
    fontSize: 20,
    fontFamily: Fonts.medium,
    textAlign: 'center',
    color: '#fff',
  },
  bankidFeedback: {
    marginBottom: 18,
  },
  bankidFeedbackText: {
    fontFamily: Fonts.medium,
    textAlign: 'center',
    color: '#fff',
  },
  buttonIconBankidImage: {
    resizeMode: 'contain',
    width: 25.4,
    height: 24,
  },
  footer: {
    marginTop: 12,
  },
  footerText: {
    fontSize: 12,
    lineHeight: 18,
    color: '#fff',
    textAlign: 'center',
  },
  footerTextLink: {
    textDecorationLine: 'underline',
  },
  footerToggleText: {
    fontSize: 16,
    lineHeight: 20,
    color: '#fff',
    textAlign: 'center',
    textDecorationLine: 'underline',
    marginTop: 20,
    marginBottom: 20,
  },
  footerErrorText: {
    fontSize: 16,
    lineHeight: 20,
    color: 'red',
    textAlign: 'center',
  },
  textInput: {
    height: 65,
    backgroundColor: '#fff',
    color: '#000',
    borderColor: '#eee',
    paddingHorizontal: 20,
    marginTop: 10,
    borderRadius: 10,
    fontSize: 16,
  },
  loginButton: {
    marginTop: 10
  },
  bottomContainer: {
    height: 30,
  }
});

const mapState = state => {
  const { bankidToken, bankidCollect, connection, login, bankidApp } = state;

  return {
    fetching: bankidToken.fetching || bankidCollect.fetching,
    orderRef: bankidToken.orderRef,
    autoStartToken: bankidToken.autoStartToken,
    qrData: bankidCollect.qrData,
    collectMessage: bankidCollect.message || bankidCollect.hintCode,
    tokenFetchError: bankidToken.fetchError,
    collectFetchError: bankidCollect.fetchError,
    tokenTestMode: bankidToken.testMode,
    isConnected: connection.isConnected,
    fetchingManualLogin: login.fetching,
    fetchingManualError: login.fetchError,
    bankidResolved: !bankidApp.launched && !bankidApp.returned,
  };
};

export default connect(mapState, {
  startBankidAuth,
  finishBankidAuth,
  returnFromBankidApp,
  doTestLogin,
  doManualLogin,
  hideManualLoginError,
})(LogInContainer);
